import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";

import {
    Stack,
    Text,
    useColorModeValue,
    Button,
    Flex,
    Image,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    useToast,
} from "@chakra-ui/react";
import { ChevronDownIcon, CheckIcon, MinusIcon } from "@chakra-ui/icons";
import { Box } from "@chakra-ui/react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import FnsCheckList from "./FnsCheckList";
import { globalStyles } from "theme/styles"

import { dateConvert, purchaseDateConvert } from "helper/dateConvert";
import { formatPrice } from "helper/formatPrice";

const CheckComponent = ({ data, refetch }) => {
    const toast = useToast();
    const textColor = useColorModeValue("gray.700", "white");
    const router = useHistory();
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [value, setValue] = useState("Выберете причину отклонения чека");
    const [img, setImg] = useState();
    const [fnsResponse, setFnsResponse] = useState([]);
    const [checkData, setCheckData] = useState([]);

    function getImg(image) {
        if (image !== undefined && image !== null) {
          const checkImg = `${process.env.REACT_APP_API_URL}/api/static/qrcodes/${image}`;
          setImg(checkImg);
          console.log(`img source link: ${checkImg}`);
        } else {
          console.error('Ошибка загрузки изображения чека')
        }

    }

    function getFnsResponse() {
        setFnsResponse(data?.fns_response ? JSON.parse(data?.fns_response) : ["none"]);

        if (data.fns_response_json)
            setCheckData(JSON.parse(data?.fns_response_json.Ticket).content.items);
    }

    async function fnsCheck() {
        try {
            setLoading(true);

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/qr/manual-verify`, {
                qrcodeId: data?.id,
            });

            setLoading(false);

            setFnsResponse(
                response.data.data.fns_response
                    ? JSON.parse(response.data.data.fns_response)
                    : ["none"]
            );

            await refetch();

            toast({
                title: `Успешно`,
                status: "success",
                isClosable: true,
                duration: 3000,
                position: "top",
            });
        } catch (error) {
            console.error(error.message);
            setLoading(false);
            toast({
                title: error.message,
                status: "error",
                isClosable: true,
                duration: 3000,
                position: "top",
            });
        }
    }

    async function verifyCheck() {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/qr/change-qrcode-status`, {
                status: "verified",
                reason: "Одобрен модератором",
                qrcodeId: data.id,
            });

            await axios.post(`${process.env.REACT_APP_API_URL}/notifications`, {
                account_id: data.account_id,
                entity_type: "Чек подтвержден",
                entity_id: data.id,
                notification_data: `Чек от: ${dateConvert(data.create_date)}`,
            });

            toast({
                title: `Чек принят`,
                status: "success",
                isClosable: true,
                duration: 3000,
                position: "top",
            });

            router.goBack();
        } catch (error) {
            toast({
                title: error.message,
                status: "error",
                isClosable: true,
                duration: 3000,
                position: "top",
            });
        }
    }
    async function notVerifyCheck() {
        try {
            if (!value) throw new Error("Выберите причину отклонения чека");
            if (value === "Выберете причину отклонения чека")
                throw new Error("Выберите причину отклонения чека");

            await axios.post(`${process.env.REACT_APP_API_URL}/qr/change-qrcode-status`, {
                status: "notVerified",
                reason: value,
                qrcodeId: data.id,
            });

            await axios.post(`${process.env.REACT_APP_API_URL}/notifications`, {
                account_id: data.account_id,
                entity_type: "Чек отклонен",
                entity_id: data.id,
                notification_data: `Чек от: ${dateConvert(data.create_date)}`,
            });

            toast({
                title: `Чек отклонен`,
                status: "error",
                isClosable: true,
                duration: 3000,
                position: "top",
            });

            router.goBack();
        } catch (error) {
            console.error(error.message);
            toast({
                title: error.message,
                status: "info",
                isClosable: true,
                duration: 3000,
                position: "top",
            });
        }
    }

    useEffect(() => {
        getFnsResponse();
    }, [data]);

    useEffect(() => {
      getImg(data.image);
    }, [process.env.REACT_APP_API_URL, data, data.image])

    return (
        <>
            <Flex direction='column' w='100%' gap='5'>
                <Button w={{ sm: "100%", md: "max-content" }} onClick={() => router.goBack()}>
                    Назад
                </Button>

                <Card width='100%'>
                    <CardHeader mb='32px'>
                        <Flex direction='column' gap='10px'>
                            <Text fontSize='lg' color={textColor} fontWeight='bold'>
                                Чек № {data.id}
                            </Text>
                        </Flex>
                    </CardHeader>

                    <CardBody>
                        <Stack direction='column' spacing='10px' w='100%'>
                            <Text fontWeight='bold'>Дата загрузки:</Text>
                            <Text>{dateConvert(data.create_date) || "none"}</Text>

                            <Text fontWeight='bold'>Дата покупки:</Text>
                            <Text>{purchaseDateConvert(data.time) || "none"}</Text>

                            <Text fontWeight='bold'>Сумма:</Text>
                            <Text>{formatPrice(data.summary) || "none"}</Text>

                            <Text fontWeight='bold'>ФД - номер:</Text>
                            <Text>{data.fd || "none"}</Text>

                            <Text fontWeight='bold'>ФН - номер:</Text>
                            <Text>{data.fn || "none"}</Text>

                            <Text fontWeight='bold'>ФП - номер:</Text>
                            <Text>{data.fp || "none"}</Text>

                            <FnsCheckList fnsResponse={fnsResponse} refetchData={refetch} />

                            <Text fontWeight='bold'>Статус проверки:</Text>
                            <Text>
                                {data.status === "notVerified" ? "Отклонен" : ""}
                                {data.status === "verified" ? "Принят" : ""}
                                {data.status === "wait" ? "На модерации" : ""}
                                {data.status === "onHold" ? "На ожидании" : ""}
                            </Text>

                            <Text fontWeight='bold'>Место покупки:</Text>
                            <Text>{data.retail_place || ""}</Text>

                            <Text fontWeight='bold'>Дополнительная информация:</Text>
                            <Text>{data.reason || ""}</Text>

                            <Text fontWeight='bold'>Подробные данные чека:</Text>
                            <Text>
                                <Flex direction='column' gap='20px'>
                                    {checkData?.map((el, index) => (
                                        <Flex direction='column' key={index}>
                                            <Text>Наименование: {el.name || "нет данных"}</Text>
                                            <Text>Количество: {el.quantity || "нет данных"}</Text>
                                            <Text>Цена за единицу: {formatPrice(el.price) || "нет данных"}</Text>
                                            <Text>Итого: {formatPrice(el.sum) || "нет данных"}</Text>
                                            <Text>
                                                ИНН поставщика: {el.providerInn || "нет данных"}
                                            </Text>
                                        </Flex>
                                    ))}
                                </Flex>
                            </Text>
                        </Stack>
                    </CardBody>
                </Card>

                <Flex align="center" gap={3}>
                  <Button w={{ sm: "100%", md: "max-content" }} onClick={() => setShow(!show)}>
                      Фото чека
                  </Button>
                  {(img !== null && img !== undefined) ? <Box color='teal.500'><CheckIcon /></Box> : <MinusIcon/>}
                </Flex>

                <Flex direction='column' gap='15px'>
                    {show && img && <Image boxSize='400px' src={img} alt='check'></Image>}
                    {(!img === null || img == undefined) && show && <Text>Для данного чека нет фото</Text>}

                    <Menu>
                        <MenuButton
                            w={{ sm: "100%", md: "max-content" }}
                            as={Button}
                            rightIcon={<ChevronDownIcon />}
                        >
                            {value}
                        </MenuButton>

                        <MenuList onClick={(e) => setValue(e.target.textContent)}>
                            <MenuItem>Некорректные данные чека</MenuItem>
                            <MenuItem>Нет акционного товара</MenuItem>
                            <MenuItem>Чек не прошел проверку ФНС</MenuItem>
                            <MenuItem>Данные чека нечитаемые</MenuItem>
                            <MenuItem>Пользователь не выполнил условия проведения акции</MenuItem>
                            <MenuItem>Дубликат</MenuItem>
                            <MenuItem>Другое</MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>

                <Stack
                    justify={{ sm: "center", md: "flex-start" }}
                    spacing={4}
                    direction={{ sm: "column", md: "row" }}
                    align='center'
                >
                    <Button
                        w={{ sm: "100%", md: "max-content" }}
                        colorScheme='green'
                        size='lg'
                        onClick={verifyCheck}
                    >
                        Принять
                    </Button>

                    <Button
                        w={{ sm: "100%", md: "max-content" }}
                        colorScheme='red'
                        size='lg'
                        onClick={notVerifyCheck}
                    >
                        Отклонить
                    </Button>

                    <Button
                        w={{ sm: "100%", md: "max-content" }}
                        colorScheme='linkedin'
                        size='lg'
                        isLoading={loading}
                        onClick={fnsCheck}
                    >
                        ФНС проверка
                    </Button>
                </Stack>
            </Flex>
        </>
    );
};

export default CheckComponent;
