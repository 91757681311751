export const formatPrice = (value) => {
  if (typeof value !== 'number' && typeof value !== 'string') return;
  
  let strValue = typeof value === 'number' ? value.toString() : value;

  if (strValue.includes('.') || strValue.includes(',')) {
    return strValue;
  }

  return strValue.slice(0, -2) + ',' + strValue.slice(-2);
};
